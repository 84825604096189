/**
 * @file NotFoundPage
 * @description Page showen when the user visits a url that doesn't have a route.
 */

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/react';

import messages from './messages';
import IntlText from '../../components/IntlText';
// import request from '../../utils/request';
import Button from '../../components/layout/Button';
// import { urlBuilder } from '../../utils/helpers';
import Loader from '../../components/Loader';
import { log, LOG_TYPE } from '../../utils/logger';

const Wrapper = styled.div`
  min-height: 70vh;
  display: flex;
  padding-bottom: 3rem;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  @media screen and (max-width: 48em) {
    min-height: calc(100vh - 286px);
  }
`;

const NotAuthorisedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
`;

const InfoText = styled.div`
  max-width: 30rem;
  margin-bottom: 1.5rem;
`;

const NotLoggedUserInfo = ({ userData }) => {
  if (userData?.userid) {
    return <IntlText index={messages.main} />;
  }

  return (
    <IntlText index={messages.notLogged} />
  );
}

const ContextButton = ({ userData }) => {

  if (!userData?.userid) {
    return (
      <Button variant="big" href={process.env.NEXT_PUBLIC_LOGIN_URL}>
        <IntlText index='app.components.AppHeader.login' />
      </Button>
    );
  }

  return (
    <Button variant="big" href={`/`}>
      <IntlText index={messages.backToHomepage} />
    </Button>
  )
}

const isPreviewInUrl = () => {
  return process.browser && window.location.href.indexOf('preview=true') > -1;
};

export function NotFound({ userData, redirectAllowed = true }) {
  // const router = useRouter();

  // useEffect(() => {
  //   if (redirectAllowed) {
  //     let lastUrl = window.location.pathname;
  //     let { referrer } = document;
  //     const is404 = window.location.pathname.substr(-4) === '/404';
  //     if (is404) {
  //       lastUrl = window.localStorage.getItem('pkb_last_url');
  //       referrer = window.localStorage.getItem('pkb_last_referrer') || referrer;
  //       window.localStorage.removeItem('pkb_last_url');
  //       window.localStorage.removeItem('pkb_last_referrer');
  //     }
  //     if (lastUrl) {
  //       request(`v1/redirects`, {
  //         method: 'POST',
  //         body: JSON.stringify({
  //           url: lastUrl, //lastUrl.substring(3).replace('guide_old', 'Guide'),
  //           referer: referrer,
  //         }),
  //       })
  //         .then(resp => {
  //           if (resp !== false && resp.id !== '-1' && !is404) {
  //             window.location.replace(urlBuilder({ item: resp }));
  //           } else if (!isPreviewInUrl()) {
  //             router.push('/404');
  //           }
  //         })
  //         .catch(e => console.error(e));
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (process.browser) {
      Sentry.captureEvent({
        message: 'Error 404 page',
        level: 'error',
        tags: {
          statusCode: '404',
          user_email: userData?.email || 'not logged in',
        },
      });
    }
  }, [])

  if (!process.browser) {
    log({
      level: LOG_TYPE.warning,
      message: 'NotFound Component',
      stringify: { userData: userData?.email || 'not logged in', redirectAllowed },
    });
  }

  return (
    <div key="notFound">
      <Wrapper>
        <h1 style={{ fontSize: '5em', marginBottom: '0' }}>
          <IntlText index={messages.header} />
        </h1>
        <InfoText>
          <NotLoggedUserInfo userData={userData} />
        </InfoText>
        <br />
        <ContextButton userData={userData} />
      </Wrapper>
    </div>
  );
}

export const NoDataInterface = ({ globalState: { isPreview, isLoggedIn, userData } }) => {
  const router = useRouter();

  const isPreviewModeActive = isPreview || isPreviewInUrl();

  if (!process.browser) {
    log({
      level: LOG_TYPE.warning,
      message: 'NoDataInterface Component',
      stringify: {
        isPreviewModeActive,
        isLoggedIn,
        userData: userData?.email || 'not logged in',
        location: {
          path: router.asPath,
          query: router.query,
        }
      },
    });
    return null;
  }

  if (!isPreviewModeActive) {
    return <NotFound userData={userData} />;
  }

  return isLoggedIn ? (
    <Loader occupySpace />
  ) : (
    <NotAuthorisedWrapper>
      <h1>Not authorised.</h1>
    </NotAuthorisedWrapper>
  );
};

export default NotFound;
