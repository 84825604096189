/**
 * @todo DiagnosticPage
 */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import loadNamespaces from 'next-translate/loadNamespaces';
import * as Sentry from '@sentry/react';

import NotFoundPage from '../app/containers/NotFoundPage';
import PageLayout from './_pageLayout';
import withGlobalData, { addStaticProps } from './_globalProps';
import { getWordpressCookie } from '../app/utils/helpers';
import { log, LOG_TYPE } from '../app/utils/logger';
import fetch from '../data/restApi/fetchApi';

const ErrorMessage = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
`;

const captureSentryEvent = (statusCode, isBrowser, userData) => {

  /**
     * code 404 is triggered in the NotFoundPage component
    */
  if (statusCode === 404) {
    return;
  }

  Sentry.captureEvent({
    message: `Error ${statusCode} page`,
    level: 'error',
    tags: {
      statusCode,
      user_email: userData?.email || 'not logged in',
    },
  });
};

function Error({ statusCode, categories, global, err, userData }) {

  useEffect(() => {
    captureSentryEvent(statusCode, process.browser, userData);
  }, [statusCode]);

  switch (statusCode) {
    case 404:
      return (
        <PageLayout global={global}>
          <NotFoundPage userData={userData} />
        </PageLayout>
      );

    default:
      return (
        <PageLayout global={global}>
          <ErrorMessage>
            {statusCode
              ? `An error ${statusCode} occurred on server`
              : 'An error occurred on client'}
          </ErrorMessage>
        </PageLayout>
      );
  }
}

const ErrorPageWithGlobalData = withGlobalData(Error);

ErrorPageWithGlobalData.getInitialProps = async ({ req, res, err, locale }) => {
  const cookie = getWordpressCookie(req?.headers?.cookie);
  const statusCode = res?.statusCode || 404;
  const referer = `/${statusCode}`;
  const currentPath = req?.url
  // const currentUrl = `${process.env.NEXT_PUBLIC_ORIGIN}${currentPath}`;

  const [redirectData, key] = await fetch.redirects.forSSR({
    language: locale,
    query: {
      url: currentPath
    }
  });

  log({
    level: LOG_TYPE.error,
    message: 'error page',
    stringify: {
      statusCode,
      referer,
      locale,
      cookie: !!cookie,
      err: err,
      request: {
        url: req?.url,
        headers: req?.headers,
        method: req?.method,
      },
    },
  })

  if (redirectData) {
    const redirect = redirectData?.[0]
    const redirectUrl = redirect?.new_url //new URL(redirect?.new_url)
    const permanent = redirect?.http_code === '301' || false

    if (redirectUrl) {
      const httpCode = permanent ? 301 : 307
      res.writeHead(httpCode, { Location: redirectUrl })
      res.end()
    }
  }

  return {
    statusCode,
    err,
    ...(await addStaticProps({ locale, referer, cookie })),
    ...(await loadNamespaces({ locale, pathname: '/' })),
  };
};

ErrorPageWithGlobalData.propTypes = {
  statusCode: PropTypes.number.isRequired,
};

export default ErrorPageWithGlobalData;
