/*
 * NotFoundPage Messages
 *
 * This contains all the text for the NotFoundPage container.
 */

export const scope = 'app.containers.NotFoundPage';
export const scope404 = 'app.404';

const messages = {
  header: `${scope}.header`,
  main: `${scope}.main`,
  backToHomepage: `${scope}.backToHomepage`,
  notLogged: `${scope404}.notLogged`,
};

export default messages;
